import React from 'react'
import Layout from '../../components/Demo/Layout'
import Yarn from '../../components/Demo/Yarn/Yarn'

const YarnPage = () => {
  const pageSlug = 'yarn'

  return (
    <Layout pageSlug={pageSlug}>
      <Yarn />
    </Layout>
  )
}

export default YarnPage
