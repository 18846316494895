import React, { useEffect } from 'react'
import gsap from 'gsap'

import TextSVG from './parts/TextSVG'
import styles from './Yarn.module.scss'
import styled from 'styled-components'

const DemoWrapper = styled.div`
  .draw-box {
    display: grid;
    place-items: center;
    height: 100vh;
    svg {
      /* margin-top: 200vh; */
      width: 40%;
    }
  }
`

const Yarn = () => {
  useEffect(() => {
    const tl = gsap.timeline()
    // gsap.from('.rectangles', {
    //   yPercent: 100,
    //   ease: 'expo.easeInOut',
    //   duration: 2,
    //   stagger: {
    //     amount: 1,
    //     from: 'center',
    //   },
    //   scrollTrigger: '#piggies',
    // })

    // const animationIsOkay = window.matchMedia(
    //   '(prefers-reduced-motion: no-preference)'
    // ).matches

    // turn this on to prevent animation for people who don't want it
    // if (animationIsOkay) {
    tl.from('#wobble', {
      xPercent: 100,
      yPercent: 100,
      duration: 2,
      // scrollTrigger: {
      //   trigger: '#piggies',
      //   pin: true,
      //   scrub: 1,
      // },
    })
      .to('.heartLine', {
        duration: 4,
        strokeDashoffset: 0,
      })
      .to('.heartLine2', {
        duration: 4,
        strokeDashoffset: 0,
      })
    // }

    const welcome = document.querySelectorAll('.heartLine')

    for (let i = 0; i < welcome.length; i++) {
      console.log(`Letter ${i} is ${welcome[i].getTotalLength()}`)
    }
  })

  return (
    <div className={styles.root}>
      <DemoWrapper className={styles.inner}>
        <div className="draw-box">
          {/* <TextSVG /> */}
          <svg viewBox="0 0 347.96 205.83">
            <path
              d="M53.48-414.83C-36.82-373-33.76-278.17-19.91-194c12.17,74,66.56,136.46,2.05,205.61s-109.6,66-153.69,147.16,31,143.15-55,230.15-274,14.62-267,206S-750.66,726.14-680.2,819.76c75,99.68,487.9,207.62,632,282.55,342.19,177.9,764,148.24,902.86-42.68C994.14,867.89,1031.26,327.21,819.11-35.12,647.45-328.3,300.19-406.44,276.48-428.27,223.48-477.07,92.55-432.94,53.48-414.83Z"
              fill="white"
            />
          </svg>
        </div>
      </DemoWrapper>
    </div>
  )
}

export default Yarn
